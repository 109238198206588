import {
  ArrowRightIcon,
  InformationCircleIcon,
  MenuIcon,
  PlusCircleIcon,
} from "@heroicons/react/outline";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";

import { useNavigate, useOutletContext } from "react-router-dom";

import { AuthContext } from "../../contexts/authContext";
import { authenticatedRequest } from "../../libs/authenticatedRequest";

export default function Commands() {
  const navigate = useNavigate();
  const [, setSidebarOpen] = useOutletContext();
  const [selectedSchool, setSelectedSchool] = useState(null);
  const auth = useContext(AuthContext);
  const API_URL = "https://api.loner.nu/v1/admin";
  const API_URL_V1 = "https://api.loner.nu/v1";
  const [user, setUser] = useState(null);
  const [filename, setFilename] = useState("");
  const [filenamev2, setFilenamev2] = useState("");

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState("");

  const rebuildSalaryReferenceFile = async () => {
    setLoading(true);
    let resp = await authenticatedRequest(auth).post(
      API_URL + "/elastic/rebuild",
      {
        command: "rebuild-file",
        filename: filename,
      }
    );
    setLoading(false);
    setResponse(resp.message);
  };

  const rebuildSalaryReferenceFileV2 = async () => {
    setLoading(true);
    let resp = await authenticatedRequest(auth).post(
      API_URL + "/elastic/rebuild",
      {
        command: "rebuild-file-v2",
        filename: filenamev2,
      }
    );
    setLoading(false);
    setResponse(resp.message);
  };

  useEffect(() => {
    async function getUser() {
      const user = await authenticatedRequest(auth).get(API_URL + "/users/me");
      setUser(user);
    }
    getUser();
  }, [auth]);

  return (
    <div className="md:pl-64 flex flex-col flex-1 w-full">
      <main className="flex-1">
        <div className="py-6">
          <div className="max-w-4xl px-4 sm:px-6 md:px-8 mb-8">
            <h1 className="text-2xl font-semibold text-gray-900">
              Admin commands för allalöner.se
            </h1>
            <p className="mt-5">Ladda in en lönefil.</p>

            <div className="flex max-w-2xl w-full">
              <input
                type="email"
                name="email"
                id="email"
                onChange={(e) => setFilename(e.target.value)}
                value={filename}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="some-file.csv"
              />
              {!loading ? (
                <button
                  type="button"
                  onClick={() => rebuildSalaryReferenceFile()}
                  className=" ml-3 rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                >
                  Skapa
                </button>
              ) : (
                ""
              )}
            </div>
            <p>{response}</p>

            <p className="mt-5">Ladda in en lönefil V2.</p>

            <div className="flex max-w-2xl w-full">
              <input
                type="email"
                name="email"
                id="email"
                onChange={(e) => setFilenamev2(e.target.value)}
                value={filenamev2}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="some-file.csv"
              />
              {!loading ? (
                <button
                  type="button"
                  onClick={() => rebuildSalaryReferenceFileV2()}
                  className=" ml-3 rounded-md bg-indigo-50 px-3 py-2 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
                >
                  Skapa
                </button>
              ) : (
                ""
              )}
            </div>
            <p>{response}</p>
          </div>

          {/* Intro Component */}
          {/* <IntroWrapper /> */}

          {/* <div className="max-w-4xl px-4 sm:px-6 md:px-8">
            <p className="">{JSON.stringify(auth.attrInfo, null, 2)}</p>
          </div> */}
        </div>
      </main>
    </div>
  );
}

import {
  ArrowRightIcon,
  InformationCircleIcon,
  MenuIcon,
  PlusCircleIcon,
} from "@heroicons/react/outline";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";

import { useNavigate, useOutletContext } from "react-router-dom";

import { AuthContext } from "../contexts/authContext";
import { authenticatedRequest } from "../libs/authenticatedRequest";
import { unAuthenticatedRequest } from "../libs/unAuthenticatedRequest";

const secret = "10c8cd2a9b44ede796d69a68e3128703";

export default function Home() {
  const navigate = useNavigate();
  const [, setSidebarOpen] = useOutletContext();
  const [selectedSchool, setSelectedSchool] = useState(null);
  const auth = useContext(AuthContext);
  const API_URL = "https://api.loner.nu/v1/admin";
  const API_URL_V1 = "https://api.loner.nu/v1";
  const [user, setUser] = useState(null);

  const rebuild = async () => {
    console.log("here");
    const result = await axios.get(API_URL + "/elastic/rebuild");
    console.log(result);
  };

  const rebuildV2 = async () => {
    const result = await axios.get(API_URL + "/elastic/rebuild-v2");
    console.log(result);
  };

  const regenerateSitemap = async () => {
    const result = await axios.get(API_URL_V1 + "/admin/sitemap/create");
    console.log(result);
  };

  const rebuildAutocomplete = async () => {
    const result = await axios.get(API_URL + "/elastic/rebuild-autocomplete");
    console.log(result);
  };

  const rebuildDynamoData = async (command) => {
    let resp = await authenticatedRequest(auth).post(
      API_URL_V1 + "/paths/rebuild",
      {
        command,
      }
    );

    if (command === "index") {
      await unAuthenticatedRequest().get(
        "https://allaloner.se/api/revalidate?secret=" + secret + "&path=/"
      );
    }

    if (command === "yrken") {
      await unAuthenticatedRequest().get(
        "https://allaloner.se/api/revalidate?secret=" + secret + "&path=/yrken"
      );
    }

    console.log("rebuilding " + command);
  };

  useEffect(() => {
    async function getUser() {
      const user = await authenticatedRequest(auth).get(API_URL + "/users/me");
      setUser(user);
    }
    getUser();
  }, [auth]);

  function signOutClicked() {
    auth.signOut();
    navigate("/");
  }

  function changePasswordClicked() {
    navigate("changepassword");
  }

  const actions = [
    { title: "Skapa yrke", func: () => navigate("/occupations") },
    { title: "Skapa arbetsgivare", func: () => navigate("/employers") },
    {
      title: "Hantera lönerapporter",
      func: () => navigate("/salary-references"),
    },
    {
      title: "Bygga om elastic V1",
      func: () => rebuild(),
    },
    { title: "Rebuild autocomplete", func: () => rebuildAutocomplete() },
  ];

  return (
    <div className="md:pl-64 flex flex-col flex-1">
      <main className="flex-1">
        <div className="py-6">
          <div className="max-w-4xl px-4 sm:px-6 md:px-8 mb-8">
            <h1 className="text-2xl font-semibold text-gray-900">Välkommen</h1>
            <p className="mt-5">Här kan du göra massa kul.</p>

            <div className="relative mt-10 mb-10">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-start">
                <span className="pr-2 bg-white text-sm text-gray-500">
                  Skapa nytt
                </span>
              </div>
            </div>

            <div className="space-x-4">
              {actions.map((action) => (
                <button
                  type="button"
                  onClick={() => action.func}
                  className="relative border-2 ml-4 mt-0 border-gray-300 border-dashed w-60 h-14 rounded-lg text-center hover:border-gray-400 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span className="mt-1 block text-sm font-medium text-gray-800">
                    {action.title}
                  </span>
                </button>
              ))}

              <button
                type="button"
                onClick={() =>
                  rebuildDynamoData("arbetsgivare-slug-yrken-yrke-all")
                }
                className="relative border-2 mt-5  border-gray-300 border-dashed w-60 h-14 rounded-lg text-center hover:border-gray-400 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="mt-1 block text-sm font-medium text-gray-800">
                  Bygg om Arbetsgivaryrken
                </span>
              </button>

              <button
                type="button"
                onClick={() => rebuildDynamoData("all")}
                className="relative border-2 mt-5  border-gray-300 border-dashed w-60 h-14 rounded-lg text-center hover:border-gray-400 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="mt-1 block text-sm font-medium text-gray-800">
                  Bygg om Dynamo
                </span>
              </button>
              <button
                type="button"
                onClick={() => rebuildDynamoData("index")}
                className="relative border-2 mt-5  border-gray-300 border-dashed w-60 h-14 rounded-lg text-center hover:border-gray-400 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="mt-1 block text-sm font-medium text-gray-800">
                  Bygg om startsidan
                </span>
              </button>

              <button
                type="button"
                onClick={() => rebuildDynamoData("yrken")}
                className="relative border-2 mt-5  border-gray-300 border-dashed w-60 h-14 rounded-lg text-center hover:border-gray-400 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="mt-1 block text-sm font-medium text-gray-800">
                  Bygg om allayrken
                </span>
              </button>

              <button
                type="button"
                onClick={() => rebuildDynamoData("yrken-slug-all")}
                className="relative border-2 mt-5  border-gray-300 border-dashed w-60 h-14 rounded-lg text-center hover:border-gray-400 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="mt-1 block text-sm font-medium text-gray-800">
                  Bygg om alla specifika yrken
                </span>
              </button>

              <button
                type="button"
                onClick={() => regenerateSitemap()}
                className="relative border-2 mt-5  border-gray-300 border-dashed w-60 h-14 rounded-lg text-center hover:border-gray-400 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="mt-1 block text-sm font-medium text-gray-800">
                  Skapa om sitemap
                </span>
              </button>

              <button
                type="button"
                onClick={() => rebuild()}
                className="relative border-2 mt-5  border-gray-300 border-dashed w-60 h-14 rounded-lg text-center hover:border-gray-400 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="mt-1 block text-sm font-medium text-gray-800">
                  Bygg om elastic V1
                </span>
              </button>

              <button
                type="button"
                onClick={() => rebuildV2()}
                className="relative border-2 mt-5  border-gray-300 border-dashed w-60 h-14 rounded-lg text-center hover:border-gray-400 focus:outline-none focus:ring-0 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="mt-1 block text-sm font-medium text-gray-800">
                  Bygg om elastic V2
                </span>
              </button>
            </div>
          </div>

          {/* Intro Component */}
          {/* <IntroWrapper /> */}

          {/* <div className="max-w-4xl px-4 sm:px-6 md:px-8">
            <p className="">{JSON.stringify(auth.attrInfo, null, 2)}</p>
          </div> */}
        </div>
      </main>
    </div>
  );
}

import React, { useContext, useEffect, useRef, useState } from "react";
import FullWithLoader from "../../components/FullWithLoader";

import { AuthContext } from "../../contexts/authContext";
import { authenticatedRequest } from "../../libs/authenticatedRequest";
import { XIcon } from "@heroicons/react/outline";
import {
  newSalaryRequestTemplateHtml,
  newSalaryRequestTemplateText,
} from "./templates";
import PublicEditor from "../../components/Editor";
import { kommunEmails } from "./emailGroups";

export default function RequestNew() {
  const auth = useContext(AuthContext);
  const API_URL = "https://api.loner.nu/v1";
  const url = API_URL + "/admin/employers";
  const [employers, setEmployers] = useState([]);
  const [selectedEmails, setSelectedEmails] = useState([]);

  const [filteredEmployers, setFilteredEmployers] = useState([]);
  const [selectedEmployers, setSelectedEmployers] = useState([]);
  const [requestText, setRequestText] = useState(newSalaryRequestTemplateText);
  const [requestHtml, setRequestHtml] = useState(newSalaryRequestTemplateHtml);

  const [loading, setLoading] = useState(false);
  const [showHTML, setShowHTML] = useState(false);

  const [input, setInput] = useState("");
  const editorRef = useRef(null);

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const updateFilteredEmployers = (input) => {
    setInput(input);
    if (input.length > 0) {
      let temp = employers.filter((e) =>
        e.display_name.toLowerCase().includes(input.toLowerCase())
      );
      setFilteredEmployers(temp);
    } else {
      setFilteredEmployers([]);
    }
  };

  const sendEmail = async (email, name) => {
    let newRequest = {
      to_address: email.trim(),
      from_address: "request@loner.nu",
      from_name: "Löner.nu",
      subject: "Begäran om allmän handling",
      text: requestHtml.replace(/<[^>]*>/g),
      text_as_html: requestHtml,
      to_name: name,
      sent: true,
    };

    let resp = await authenticatedRequest(auth).post(
      API_URL + "/admin/emails",
      newRequest
    );
  };

  const createRequests = async () => {
    setLoading(true);
    let sent = 0;
    for (let email of selectedEmails) {
      await sleep(3000);
      await sendEmail(email, email);
      console.log("Sent - " + email);
      sent++;
      console.log(sent);
    }

    setLoading(false);
  };

  useEffect(() => {
    const getDatas = async () => {
      let data = await authenticatedRequest(auth).get(url);

      data = data.filter((i) => i.is_public === 1);

      data.sort((a, b) =>
        a.display_name.toLowerCase() > b.display_name.toLowerCase() ? -1 : 1
      );

      setEmployers(data);
      setFilteredEmployers(data);
    };
    getDatas();
  }, [url, auth]);

  const updateSelectedEmployers = (index) => {
    let temp = selectedEmployers.filter((e, i) => i !== index);
    setSelectedEmployers(temp);
  };

  return (
    <div className="md:pl-64">
      <main className="">
        <div className="pt-6">
          <div className="flex justify-between mx-auto px-2 sm:px-6 md:px-4">
            <h1 className="text-2xl font-semibold text-gray-900">
              Ny förfrågan
            </h1>
          </div>
          <div className="px-4 mt-8 font-semibold text-sm">
            <div className=" max-w-4xl">
              <div className="mb-2">Välj myndigheter</div>
              <input
                onChange={(e) => updateFilteredEmployers(e.target.value)}
                type="text"
                value={input}
                className="w-full max-w-3xl ring-0 focus:ring-0 border outline-none focus:outline-none"
              />

              {input.length > 2 && (
                <div className="z-10 bg-white max-w-xl w-full absolute border text-gray-700 font-normal">
                  {filteredEmployers.toReversed().map((e, i) => (
                    <div
                      className="border-b py-1 px-2 hover:bg-gray-50 cursor-pointer"
                      onClick={() => {
                        setSelectedEmployers([...selectedEmployers, e]);
                        setInput("");
                      }}
                      key={i}
                    >
                      {e.display_name}
                    </div>
                  ))}
                </div>
              )}

              <div className="flex">
                <div className="flex flex-col mt-3 space-y-1 max-w-sm">
                  {selectedEmployers.map((e, i) => (
                    <div className="flex flex-col">
                      <p
                        key={i}
                        className="inline-block px-2 py-1 mr-1  border font-normal text-xs"
                      >
                        <XIcon
                          onClick={() => updateSelectedEmployers(i)}
                          className="cursor-pointer w-4 h-4 text-gray-700 inline-block -mt-0.5 mr-1 break-words"
                        />
                        {e.display_name} -{" "}
                        {e.registrator_emails
                          ? e.registrator_emails.split(",").map((email) => (
                              <div
                                onClick={() =>
                                  setSelectedEmails([...selectedEmails, email])
                                }
                                className="bg-gray-200 cursor-pointer rounded px-1 py-1 my-1 block"
                              >
                                {email}
                              </div>
                            ))
                          : ""}
                        {e.registrator_email ? (
                          <div
                            onClick={() =>
                              setSelectedEmails([
                                ...selectedEmails,
                                e.registrator_email,
                              ])
                            }
                            className="bg-gray-200 cursor-pointer rounded px-1 py-1 my-1 block"
                          >
                            {e.registrator_email}
                          </div>
                        ) : (
                          ""
                        )}
                        {!e.registrator_email && !e.registrator_emails ? (
                          <span className="text-red-600">
                            email saknas{" "}
                            <a
                              className="text-blue-600 underline underline-offset-1"
                              target="_blank"
                              rel="noreferrer"
                              href={"/#/employers/" + e.id}
                            >
                              uppdatera
                            </a>
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  ))}
                </div>
                <p className="inline-block px-2 py-1 mr-1 mt-3  border font-normal text-xs">
                  Selected emails
                  {selectedEmails.map((semail, i) => (
                    <div className="bg-gray-200 cursor-pointer rounded px-1 py-1 my-1 block">
                      {semail}
                    </div>
                  ))}
                </p>
              </div>
            </div>
            <div className=" max-w-4xl mt-4 flex flex-col">
              <hr />

              {!loading ? (
                <>
                  <div className="mt-4">Meddelande</div>
                  {/* <textarea
                    className="text-gray-700 h-96 text-sm font-normal w-full max-w-xl"
                    disabled={false}
                    value={requestText}
                    onChange={(e) => setRequestText(e.target.value)}
                  >
                    {newSalaryRequestTemplateText}
                  </textarea> */}

                  <PublicEditor
                    initialValue={newSalaryRequestTemplateHtml}
                    editorRef={editorRef}
                    height={400}
                    setRequestHtml={setRequestHtml}
                  />
                  <div className="mt-5">
                    <hr />
                  </div>

                  <div className="flex justify-between">
                    <h3 className="text-lg mt-6 mb-6 inline">
                      Såhär kommer ditt email se ut
                    </h3>
                    <div>
                      <button
                        onClick={() => createRequests()}
                        className="w-fit mt-4 px-3 py-2 bg-blue-600 text-white rounded"
                      >
                        Skicka
                      </button>

                      <button
                        onClick={() => setShowHTML(!showHTML)}
                        className="ml-10 w-fit mt-4 px-3 py-2 bg-gray-200 text-gray-800 rounded"
                      >
                        Visa/Dölj html
                      </button>
                    </div>
                  </div>

                  {editorRef.current && showHTML ? (
                    <p
                      className="border py-4 px-2 text-wrap break-words"
                      dangerouslySetInnerHTML={{
                        __html: editorRef.current.getContent(),
                      }}
                    ></p>
                  ) : (
                    ""
                  )}

                  {editorRef.current && !showHTML ? (
                    <p className="border py-8 px-8 break-words">
                      {editorRef.current.getContent()}
                    </p>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <FullWithLoader />
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

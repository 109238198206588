import { useState } from "react";
import { Outlet } from "react-router-dom";
import Navigation from "../components/navigation";
import Top from "../components/top";

export default function Portal() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <div className="">
      {/* <Top></Top> */}
      <Navigation sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className="flex flex-col flex-1">
        <div className="">
          <Outlet context={[sidebarOpen, setSidebarOpen]}></Outlet>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useContext } from "react";

import { useNavigate } from "react-router-dom";

import { useValidEmail, useValidPassword } from "../../hooks/useAuthHooks";

import { AuthContext } from "../../contexts/authContext";
import { unAuthenticatedRequest } from "../../libs/unAuthenticatedRequest";

const SignUp: React.FunctionComponent<{}> = () => {
  const { email, setEmail } = useValidEmail("");
  const { password, setPassword } = useValidPassword("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const API_URL = "https://api.loner.nu/v1/admin";

  const navigate = useNavigate();

  const authContext = useContext(AuthContext);

  const loginClicked = async () => {
    navigate("/signIn");
  };

  const signUpClicked = async () => {
    try {
      setLoading(true);
      const cognitoUser = await authContext.signUpWithEmail(
        email,
        email,
        password
      );
      const user = await unAuthenticatedRequest().post(API_URL + "/users", {
        email,
        id: cognitoUser.userSub,
      });

      console.log(user);

      navigate("/verify");
      setLoading(false);
    } catch (err) {
      setLoading(false);

      if (err instanceof Error) {
        setError(err.message);
      }
    }
  };

  const passwordResetClicked = async () => {
    navigate("/requestcode");
  };

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Registrera konto
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Har du redan ett konto? Då kan du{" "}
            <span
              onClick={() => loginClicked()}
              className="font-medium text-primary-600 hover:text-primary-500 cursor-pointer"
            >
              logga in här.
            </span>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-2xl sm:px-10">
            <form className="space-y-6" action="#" method="POST">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-bold text-gray-700"
                >
                  Email:
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    autoComplete="off"
                    name="email"
                    type="email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-bold text-gray-700"
                >
                  Lösenord:
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    autoComplete="off"
                    name="password"
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <button
                  type="button"
                  onClick={() => signUpClicked()}
                  className="w-full font-bold flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  {loading ? (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                  ) : (
                    ""
                  )}
                  {loading ? " " : "Registrera konto "}
                </button>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-sm">
                  <span
                    onClick={passwordResetClicked}
                    className="font-medium text-primary-600 hover:text-primary-500 cursor-pointer"
                  >
                    Glömt lösenord?
                  </span>
                </div>
              </div>
              {error ? (
                <div className="mt-4 text-red-800 text-center">{error}</div>
              ) : (
                ""
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;

import {
  DocumentIcon,
  DocumentTextIcon,
  PlusIcon,
  RefreshIcon,
  SearchIcon,
  XIcon,
} from "@heroicons/react/outline";
import React, { useContext, useEffect, useRef, useState } from "react";
import FullWithLoader from "../../components/FullWithLoader";

import { AuthContext } from "../../contexts/authContext";
import { authenticatedRequest } from "../../libs/authenticatedRequest";
import { Link, useNavigate } from "react-router-dom";
import PublicEditor from "../../components/Editor";

export default function RequestList() {
  const navigate = useNavigate();

  const API_URL = "https://api.loner.nu/v1";
  const [sent, setSent] = useState(false);
  const auth = useContext(AuthContext);

  let [expandedRows, setExpandedRows] = useState({});
  const [emails, setEmails] = useState([]);
  const [isLoadingNewMessage, setIsLoadingNewMessage] = useState(false);

  const [newReponse, setNewResponse] = useState({});
  const [sendingError, setSendingError] = useState(null);
  const [sendingMessage, setSendingMessage] = useState(null);

  const [showNewMessage, setShowNewMessage] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const editorRef = useRef(null);

  const getFile = async (key) => {
    const file = await authenticatedRequest(auth).get(
      "https://api.loner.nu/v1/admin/files?key=" + key
    );
    window.open(file.url);
  };

  const loadNewResponse = (message) => {
    setShowNewMessage(false);
    setNewResponse({});
    let tempResponse = {};
    tempResponse.subject = "Re: " + message.subject;
    tempResponse.to_address = sent ? message.to_address : message.from_address;
    tempResponse.to_name = sent ? message.to_name : message.from_name;
    tempResponse.from_name = sent ? message.from_name : message.to_name;
    tempResponse.from_address = sent
      ? message.from_address
      : message.to_address;
    tempResponse.text_as_html = message.text_as_html;
    tempResponse.text = message.text;
    tempResponse.sent = true;
    setNewResponse(tempResponse);
    setShowNewMessage(true);
  };

  const createRequest = async () => {
    setSendingError("");
    setSendingMessage("");

    setIsLoadingNewMessage(true);
    newReponse.text_as_html = newMessage;
    newReponse.text = newMessage.replace(/<[^>]*>/g);

    if (!newReponse.to_name) {
      newReponse.to_name = newReponse.to_address.split("@")[0];
    }

    let resp = await authenticatedRequest(auth).post(
      API_URL + "/admin/emails",
      newReponse
    );

    if (resp.statusCode === 400) {
      setSendingError("Kunde ej skicka meddelande");
    }

    setSendingMessage("Meddelande skickat!");
    setNewMessage("");
    setNewResponse({});
    setShowNewMessage(false);

    setIsLoadingNewMessage(false);
  };

  const refreshEmails = async () => {
    setEmails([]);
    const data = await authenticatedRequest(auth).get(
      API_URL + "/admin/emails"
    );
    setEmails(data);
  };

  useEffect(() => {
    const getDatas = async () => {
      const data = await authenticatedRequest(auth).get(
        API_URL + "/admin/emails"
      );
      setEmails(data);
    };

    getDatas();
  }, [API_URL]);

  const expandRow = (i) => {
    let temp = expandedRows;

    if (expandedRows[i]) {
      delete temp[i];
    } else {
      temp[i] = true;
    }
    setExpandedRows({ ...temp });

    console.log(expandedRows);
  };

  return (
    <div className="md:pl-64 flex flex-col flex-1">
      <main className="flex-1 min-h-screen">
        <div className="pt-6">
          <div className="flex justify-between mx-auto px-2 sm:px-6 md:px-4">
            <h1 className="text-2xl font-semibold text-gray-900">
              Förfrågningar
            </h1>
          </div>
          <div className="px-4 mt-8 font-semibold text-sm">
            <div className="flex justify-between">
              <div className="">
                <button
                  onClick={() => setSent(false)}
                  className={
                    "cursor-pointer px-2 py-1 border  rounded-full mr-2 " +
                    (!sent
                      ? "bg-blue-600 text-white"
                      : "bg-gray-100 text-gray-700")
                  }
                >
                  Inkorg
                </button>

                <button
                  onClick={() => setSent(true)}
                  className={
                    "cursor-pointer px-2 py-1 border  rounded-full " +
                    (sent
                      ? "bg-blue-600 text-white"
                      : "bg-gray-100 text-gray-700")
                  }
                >
                  Skickat
                </button>
                <span className="inline-block mx-2"> | </span>
                <button
                  onClick={() => navigate("/request/new")}
                  className={
                    "cursor-pointer px-2 py-1 border  rounded-full text-white bg-emerald-600 "
                  }
                >
                  <PlusIcon className="h-4 w-4 inline-block mr-1 -mt-0.5" />
                  Skapa ny
                </button>
                <span className="ml-4 align-middle inline-block font-medium text-green-700">
                  {sendingMessage}
                </span>
              </div>

              <div className="mr-4">
                <button
                  className="bg-gray-100 py-1 px-1 ml-4 rounded-full"
                  onClick={() => refreshEmails()}
                >
                  <RefreshIcon className="inline-block text-gray-700 h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="mt-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full   align-middle">
                <div className="overflow-hidden shadow-sm border-t px-6 py-2">
                  <form className="w-full flex md:ml-0" action="#" method="GET">
                    <label htmlFor="search-field" className="sr-only">
                      Sök
                    </label>
                    <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                      <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                        <SearchIcon className="h-5 w-5" aria-hidden="true" />
                      </div>
                      <input
                        id="search-field"
                        className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                        placeholder="Search"
                        type="search"
                        name="search"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle">
                  <div className="overflow-hidden shadow-sm border-t">
                    {emails.length > 0 ? (
                      <table className="min-w-full divide-y divide-gray-300 table-auto">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              {sent ? "Till" : "Från"}
                            </th>

                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                            >
                              Ämne
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Filer
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Tid
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            ></th>
                          </tr>
                        </thead>
                        <tbody className=" bg-white">
                          {emails
                            .filter((e) => e.sent == sent)
                            .toReversed()
                            .map((e, i) => (
                              <>
                                <tr className={"border-b py-2"}>
                                  <td className="whitespace-nowrap px-3  text-sm text-gray-500">
                                    {sent ? e.to_address : e.from_address}
                                  </td>
                                  <td
                                    onClick={() => expandRow(i)}
                                    className="whitespace-nowrap cursor-pointer  pl-4 pr-3 text-sm text-gray-900 sm:pl-6 lg:pl-8"
                                  >
                                    {e.subject}
                                  </td>
                                  <td className="whitespace-nowrap px-3  text-sm text-gray-500">
                                    {e.attachements ? (
                                      <DocumentTextIcon className="w-5 h-5 inline-block text-green-500" />
                                    ) : (
                                      ""
                                    )}
                                  </td>
                                  <td className="whitespace-nowrap px-3  text-sm text-gray-500">
                                    {e.created_at.substring(0, 10)}{" "}
                                    {e.created_at.substring(11, 16)}
                                  </td>
                                  <td className="cursor-pointer whitespace-nowrap px-3 text-sm text-gray-500">
                                    <button
                                      onClick={() => loadNewResponse(e)}
                                      className="px-2 py-1 my-1 bg-blue-500 text-xs text-white font-medium rounded"
                                    >
                                      Svara
                                    </button>
                                  </td>
                                </tr>

                                {expandedRows[i] ? (
                                  <tr className="border-b">
                                    <td
                                      className="px-6 text-gray-700 text-sm pb-5 pt-5"
                                      colSpan={4}
                                    >
                                      {e.attachements ? (
                                        <div className="pl-2 mt-2 mb-6">
                                          <span className="font-medium text-gray-800 block mb-4">
                                            Attachements
                                          </span>
                                          {e.attachements
                                            .split(",")
                                            .map((a) => (
                                              <span
                                                key={a}
                                                onClick={() => getFile(a)}
                                                className="text-blue-500 underline py-2 cursor-pointer"
                                              >
                                                {a}
                                              </span>
                                            ))}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      <span
                                        className="border-l block pl-5"
                                        dangerouslySetInnerHTML={{
                                          __html: e.text_as_html,
                                        }}
                                      />
                                    </td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                              </>
                            ))}
                        </tbody>
                      </table>
                    ) : (
                      <FullWithLoader />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showNewMessage ? (
          <div className="rounded fixed bottom-0 bg-white right-0 w-3/6 h-5/6 border-l border-t shadow z-50">
            <div className="bg-slate-100 py-2 px-2 font-medium text-sm text-gray-800">
              <div className="flex justify-between">
                <span className="inline-block">
                  {newReponse.subject ? newReponse.subject : "Nytt meddelande"}
                </span>
                <XIcon
                  className="h5 w-5 text-gray-700 cursor-pointer"
                  onClick={() => setShowNewMessage(false)}
                ></XIcon>
              </div>
            </div>
            <div className="bg-white border-b py-2 px-2 font-medium text-sm text-gray-800">
              <span className="font-bold">Till: </span>
              {newReponse.to_address}
            </div>
            <div className="bg-white border-b py-2 px-2 font-medium text-sm text-gray-800">
              <span className="font-bold">Från: </span>
              {newReponse.from_address}
            </div>
            <div className="bg-white"></div>

            <div className="border-b ">
              {/* <textarea
                value={newMessage}
                placeholder="Skriv något nytt här"
                onChange={(e) => setNewMessage(e.target.value)}
                className=" text-gray-700 w-full h-40 overflow-y-scroll border-blue-600 "
              ></textarea> */}

              <PublicEditor
                initialValue={newReponse.text_as_html}
                editorRef={editorRef}
                height={400}
                setRequestHtml={setNewMessage}
              />

              {/* <textarea
                value={newReponse.text}
                disabled
                className="focus:ring-0 border-0 text-gray-700 w-full h-48 overflow-y-scroll "
              ></textarea> */}
            </div>
            {isLoadingNewMessage ? (
              <FullWithLoader />
            ) : (
              <div className="">
                <button
                  onClick={() => createRequest()}
                  className="inline ml-4 mt-4 px-3 py-2 bg-blue-500 text-white font-bold rounded"
                >
                  Skicka
                </button>
                <span className="ml-4 underline underline-offset-2 align-middle inline-block font-medium text-red-700">
                  {sendingError}
                </span>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
      </main>
    </div>
  );
}

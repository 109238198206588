export default function CheckBox({ checked, setChecked }) {
  return (
    <input
      key={Math.random()}
      id="checked-checkbox"
      type="checkbox"
      checked={checked}
      onChange={(e) => setChecked(e.target.checked)}
      className="cursor-pointer border-2 -mt-0.5 inline-block w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500  focus:ring-2 "
    />
  );
}

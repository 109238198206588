import {
  AcademicCapIcon,
  ArrowUpIcon,
  BriefcaseIcon,
  ClockIcon,
  FlagIcon,
  MapIcon,
  OfficeBuildingIcon,
} from "@heroicons/react/outline";
import { employmentTypes, salaryTypes } from "../data/constants";
import { useEffect, useState } from "react";
import { authenticatedRequest } from "../libs/authenticatedRequest";
import { unAuthenticatedRequest } from "../libs/unAuthenticatedRequest";

const reference = {
  jobTitle: "Röntgensjuksköterska",
  occupation: "Sjuksköterska",
  group: "Röntgensjuksköterska",
  totExp: 10,
  expAtCompany: 0,
  employeer: {
    name: "Region Stockholm",
    location: "Stockholm",
    timestamp: "",
    remote: false,
  },
  sex: "",
  origin: "Sweden",
  education: "Bachelor",
};

const API_URL = "https://api.loner.nu/v1/admin";

export default function SalaryReference({ reference }) {
  const [employer, setEmployer] = useState(null);

  useEffect(() => {
    const getDatas = async () => {
      const data = await unAuthenticatedRequest().get(
        API_URL + "/employers/" + reference.employer_id
      );

      setEmployer(data);
    };
    getDatas();
  }, [API_URL]);

  return (
    <div className="px-5 py-7 flex flex-col mb-10 w-full">
      <div className="flex flex-col sm:flex-row">
        <div className="sm:mr-10">
          <h3 className="text-xl">{reference.title}</h3>
          <div className="flex flex-col mt-4">
            <div className="flex flex-col mr-20">
              <span className="text-sm font-medium text-gray-700">
                {reference.occupation}
              </span>
            </div>
            <div className="flex flex-col">
              <span className="text-sm font-medium text-gray-700">
                {reference.focus}
              </span>
            </div>
          </div>
          <div className="mt-10">
            <span className="font-medium text-base mb-2 block">
              Information
            </span>
            <div className="bg-slate-100 rounded-lg px-3 py-3 text-gray-600 font-light text-sm">
              <OfficeBuildingIcon className="w-5 h-5 text-gray-500 inline-block mr-2 -mt-1" />{" "}
              {employer ? employer.name : ""}
            </div>

            <div className="bg-slate-100 rounded-lg px-3 py-3 text-gray-600 font-light text-sm mt-2">
              <MapIcon className="w-5 h-5 text-gray-500 inline-block mr-2 -mt-1" />{" "}
              {reference.workplace}
            </div>

            <div className="bg-slate-100 rounded-lg px-3 py-3 text-gray-600 font-light text-sm mt-2">
              <ClockIcon className="w-5 h-5 text-gray-500 inline-block mr-2 -mt-1" />{" "}
              {reference.created_at}
            </div>
            <div className="bg-slate-100 rounded-lg px-3 py-3 text-gray-600 font-light text-sm mt-2">
              <BriefcaseIcon className="w-5 h-5 text-gray-500 inline-block mr-2 -mt-1" />{" "}
              {employmentTypes[reference.employment_type].display_name}
            </div>

            <div className="flex flex-row mt-6 justify-between">
              <div className="flex flex-col mr-3">
                <span className="font-medium text-sm mb-2 block pl-1">
                  År på bolaget
                </span>
                <span className="bg-slate-100 w-32 rounded-lg px-3 py-3 text-gray-600 font-light text-sm">
                  {reference.years_at_company} år
                </span>
              </div>
              <div className="flex flex-col">
                <span className="font-medium text-sm mb-2 block pl-1">
                  Total erfareneht
                </span>
                <span className="bg-slate-100 w-32 rounded-lg px-3 py-3 text-gray-600 font-light text-sm">
                  {reference.years_of_experience} år
                </span>
              </div>
            </div>

            <div className="mt-6">
              <span className="font-medium text-base mb-2 block">
                Utbildning
              </span>
              <div className="bg-slate-100 rounded-lg px-3 py-3 text-gray-600 font-light text-sm mt-2">
                <AcademicCapIcon className="w-5 h-5 text-gray-500 inline-block mr-2 -mt-1" />{" "}
                -
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full mt-10 md:mt-0">
          <span className="font-medium text-md">Löneinformation</span>
          <div className="bg-slate-100 rounded-lg px-6 py-3 text-gray-800 font-medium text-xl mt-2">
            {reference.salary} kr /{" "}
            {salaryTypes[reference.salary_type].display_name_short}
          </div>
        </div>
      </div>
    </div>
  );
}

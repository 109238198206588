export const newSalaryRequestTemplateHtml = `<p >Hej,</span></p>
<p >Med st&ouml;d av tryckfrihetsf&ouml;rordningens regler om handlingsoffentlighet beg&auml;r jag h&auml;rmed kopior av handlingen &ldquo;Novemberstatistiken&rdquo; eller motsvarande &ldquo;l&ouml;nelista&rdquo; fr&aring;n dagens datum eller senaste sammanst&auml;llning. Novemberstatistiken beskrivs av SKR enligt nedan.</span></p>
<p >&ldquo;SKR samlar in och redovisar &aring;rligen statistik &ouml;ver anst&auml;llda och l&ouml;ner i kommuner och regioner per november, ofta kallad novemberstatistiken.&rdquo;&nbsp;</span></p>
<p style="text-align: start;color: rgb(34, 34, 34);background-color: rgb(255, 255, 255);font-size: 16px;"><strong><span style="color: black;">Mer g&aring;r att l&auml;sa h&auml;r:</span></strong></p>
<p ><a title="Klicka för att följa länken https://skr.se/skr/arbetsgivarekollektivavtal/uppfoljninganalys/personalstatistik/omnovemberstatistiken.46602.html" href="https://skr.se/skr/arbetsgivarekollektivavtal/uppfoljninganalys/personalstatistik/omnovemberstatistiken.46602.html" target="_blank" style="color: rgb(17, 85, 204);">https://skr.se/skr/arbetsgivarekollektivavtal/uppfoljninganalys/personalstatistik/omnovemberstatistiken.46602.html</a></span></p>
<p >I l&ouml;nelistan eller novemberstatistiken ber jag er v&auml;nligen inkludera f&auml;lten nedan med en rad per individ. Notera att jag <strong>inte</strong> &auml;r intresserad av att f&aring; ut <strong>namn</strong>. Syftet &auml;r en &ouml;vergripande forskningsstudie kring l&ouml;ner i offentlig sektor. Jag har inget intresse av att identifiera enskilda individer.</span></p>
<p >F&ouml;redraget filformat &auml;r excel.</span></p>
<p style="text-align: start;color: rgb(34, 34, 34);background-color: rgb(255, 255, 255);font-size: 16px;"><strong><span style="color: black;">F&auml;lt:</span></strong></p>
<p >Personnummer <strong>enbart 4 f&ouml;rsta</strong> (exe 1990,1958 eller 1960)</span></p>
<p >Arbetsst&auml;llenummer</span></p>
<p >Arbetsst&auml;llenummer (cfarNr)</span></p>
<p >Anst&auml;llningsform</span></p>
<p >Ben&auml;mning/Kategori</span></p>
<p >M&aring;nadsbelopp</span></p>
<p >L&ouml;netill&auml;gg</span></p>
<p >L&ouml;netyp (m&aring;nadsl&ouml;n/timl&ouml;n)</span></p>
<p >AID Kod ( exe 206010)</span></p>
<p >AID Etikett (exe Anestesisjuksk&ouml;terska)</span></p>
<p >AID L&auml;karspec</span></p>
<p >Syssels&auml;ttningsgrad</span></p>
<p >F&ouml;rvaltning (Steg 1 organisation eller liknande)</span></p>
<p >Division (Steg 2 organisation eller liknande)</span></p>
<p >Enhet (Steg 3 organisation eller liknande)</span></p>
<p >K&ouml;n</span></p>
<p >&Aring;lder</span></p>
<p style="text-align: start;color: rgb(34, 34, 34);background-color: rgb(255, 255, 255);font-size: 16px;">&nbsp;</p>
<p >H&ouml;r av er om det &auml;r n&aring;got som &auml;r oklart!</span></p>
<p >Med v&auml;nliga h&auml;lsningar,</span></p>

`;

export const newSalaryRequestTemplateText = `Hej,

Med stöd av tryckfrihetsförordningens regler om handlingsoffentlighet begär jag härmed kopior av handlingen “Novemberstatistiken” eller motsvarande “lönelista” från dagens datum eller senaste sammanställning. Novemberstatistiken beskrivs av SKR enligt nedan.

“SKR samlar in och redovisar årligen statistik över anställda och löner i kommuner och regioner per november, ofta kallad novemberstatistiken.” 

Mer går att läsa här:

https://skr.se/skr/arbetsgivarekollektivavtal/uppfoljninganalys/personalstatistik/omnovemberstatistiken.46602.html

I lönelistan eller novemberstatistiken ber jag er vänligen inkludera fälten nedan med en rad per individ. Notera att jag inte är intresserad av att få ut namn. Syftet är en övergripande forskningsstudie kring löner i offentlig sektor. Jag har inget intresse av att identifiera enskilda individer.

Föredraget filformat är excel.

Fält:

Personnummer enbart 4 första (exe 1990,1958 eller 1960)

Arbetsställenummer
Arbetsställenummer (cfarNr)
Anställningsform
Benämning/Kategori
Månadsbelopp
Lönetillägg
Lönetyp (månadslön/timlön)
AID Kod ( exe 206010)
AID Etikett (exe Anestesisjuksköterska)
AID Läkarspec
Sysselsättningsgrad
Förvaltning (Steg 1 organisation eller liknande)
Division (Steg 2 organisation eller liknande)
Enhet (Steg 3 organisation eller liknande)
Kön
Ålder

Hör av er om det är något som är oklart!

Med vänliga hälsningar,`;
